import { createContext, useCallback, useEffect, useState } from "react";
import { getUser, logout } from "../api/auth";

import Loader from "../components/Loader";
import { NotificationType } from "../types/types";
import { User } from "../types/types";
import { getFollowedDocuments } from "../api/followed";
import { getNotifications } from "../api/notifications";
import { useNavigate } from "react-router-dom";

type UserContextValue = {
    user: User | null;
    userType: string | null;
    token: string | null;
    notifications: NotificationType[] | null;
    isVerified: boolean;
    setToken: (token: string | null) => void;
    setUser: React.Dispatch<React.SetStateAction<User | null>>;
    getMe: React.Dispatch<React.SetStateAction<User | null>>;
    logoutFonction: () => void;
    logout: (token: string | null) => Promise<void>;
};

const UserContext = createContext<UserContextValue>({
    user: null,
    userType: null,
    notifications: null,
    token: null,
    isVerified: false,
    setToken: () => {},
    setUser: () => {},
    logoutFonction: () => {},
    logout: async () => {},
    getMe: async () => {},
});

type UserContextProviderProps = {
    children: React.ReactNode;
};

const UserContextProvider: React.FC<UserContextProviderProps> = ({ children }: any) => {
    const [token, setToken] = useState<string | null>(null);
    const [user, setUser] = useState<User | null>(null);
    const [userType, setUserType] = useState<string | null>(null);
    const [isVerified, setIsVerified] = useState(false);
    const [notifications, setNotifications] = useState<any[] | null>(null); // Replace 'any' with the actual type of notifications
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const type = localStorage.getItem("type");

    useEffect(() => {
        setLoading(false);
        setUserType(type);
    }, [type]);

    const getMe = useCallback(async () => {
        if (!type) return;
        const userResponse = await getUser(type);
        setUser(userResponse);
    }, [type]);

    useEffect(() => {
        if (!type) return;
        getMe();
        if (type === "individual") {
            getUserNotifications();
            getVerifiedDocument();
        }
    }, [type, getMe]);

    const getUserNotifications = async () => {
        try {
            const request = await getNotifications(userType);
            if (Array.isArray(request)) {
                setNotifications(request);
            }
        } catch (error) {
            console.error("Failed to fetch notifications:", error);
        }
        return notifications;
    };

    const getVerifiedDocument = async () => {
        try {
            if (userType === "partner") {
                const response = await getFollowedDocuments(userType);
                const isVerified = response.some((document: any) => document.stateDoc === "SUIVI");
                setIsVerified(isVerified);
            } else {
                setIsVerified(true);
            }
        } catch (error) {
            console.error("Failed to fetch verified document:", error);
        }
    };

    const logoutFonction = async () => {
        await logout();
        navigate("/");
        setUser(null);
    };

    const value: UserContextValue = {
        user,
        setUser,
        userType,
        notifications,
        token,
        isVerified,
        getMe,
        setToken,
        logoutFonction,
        logout,
    };

    if (loading) {
        return (
            <div>
                <Loader />
            </div>
        );
    }

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export { UserContext, UserContextProvider };
