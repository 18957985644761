import "./style.scss";

import Button, { Button_Style, Button_Type } from "../Button/Button";
import { FC, useContext, useEffect, useState } from "react";

import Alert from "../Alert";
import { DocumentBasylic } from "../../types/types";
import DocumentError from "../../assets/img/document_warning.svg";
import Loader from "../Loader";
import ShareID from "@shareid/onboarding_desktop";
import Skeleton from "../Skeleton";
import { UserContext } from "../../contexts/UserContext";
import { useDataByUUID } from "../../api/analysis";

type ShareIDVerificationProps = {
    accessToken: string;
    applicantId?: string;
    setIsScanning?: any;
    setIsScanned?: any;
    setErrorType?: any;
    setDocument?: any;
    errorType?: any;
    setIsShareID: any;
};

const ShareIDVerification: FC<ShareIDVerificationProps> = ({
    accessToken,
    applicantId,
    setIsScanning,
    setIsScanned,
    setErrorType,
    setDocument,
    errorType,
    setIsShareID,
}) => {
    const [isQueryEnabled, setIsQueryEnabled] = useState(false);
    const { userType } = useContext(UserContext);
    const { data, error, isLoading } = useDataByUUID(userType, applicantId, isQueryEnabled);

    const formatDocument = (document: any) => {
        const { doc_num, surname, name, sex, expiration_date, issuance_date, mrz_1, mrz_2, birth_date, birth_place } =
            document.document.ocr;
        const documentFormatted: DocumentBasylic = {
            id: document.id,
            // TODO: vérifier la validité de ces valeurs
            conformite: true,
            darkWeb: false,
            country_code: document.document.type.country_code,
            dateDelivrance: issuance_date,
            dateExpiration: expiration_date,
            dateNaissance: birth_date,
            nom: name,
            nomUsage: surname,
            numeroDocument: doc_num,
            paysNaissance: birth_place,
            prenom: surname,
            sexe: sex,
            typeDocument: document.document.type.doc_type,
            mrz: mrz_1,
            viz: mrz_2,
        };
        return documentFormatted;
    };

    // add the class IDVerify__container to first div in the component ShareID

    const addClassToShareID = () => {
        const shareID = document.querySelector(".IDVerify");
        const container = shareID?.children[0];
        const footer = shareID?.children[1];
        const header = container?.children[0]?.children[0];

        header?.classList.add("IDVerify__header");
        header?.classList.remove("sdk-flex");

        container?.classList.add("IDVerify__container");
        container?.classList.remove("sdk-h-dvh", "md:sdk-pt-14", "sdk-pt-3", "sdk-px-3");
        container?.children[0]?.classList.remove("md:sdk-px-6", "sdk-px-3");

        footer?.classList.add("IDVerify__footer");
    };

    useEffect(() => {
        addClassToShareID();
    }, [accessToken]);

    const handleVerify = () => {
        setIsQueryEnabled(true);
    };

    useEffect(() => {
        if (data) {
            const document: any = formatDocument(data.data.documentAnalyse);
            setDocument(document);
            setIsScanned(true);
            setIsScanning(false);
        } else if (error) {
            setIsScanning(false);
        }
    }, [data, error]);

    if (isLoading)
        return (
            <div>
                <h3>Analyse du document</h3>

                <Skeleton
                    width="90%"
                    height={28}
                />
                <Skeleton
                    width="100%"
                    height={228}
                />
                <Skeleton
                    width="50%"
                    height={26}
                />
                <Skeleton
                    width="90%"
                    height={40}
                />
                <Loader text="L'analyse peut prendre quelques minutes." />
            </div>
        );

    if (error) {
        return (
            <div>
                <img
                    src={DocumentError}
                    alt="ID Protect - Document non conforme"
                    className="mb-2"
                />
                <Alert type="error">Erreur lors de la recherche: votre document n'a pas été retrouvé </Alert>
                <Button
                    label="Recommencer"
                    type={Button_Type.PRIMARY}
                    className="m-auto"
                    onClick={() => {
                        window.location.reload();
                    }}
                />
            </div>
        );
    }

    return (
        <div className="IDVerify">
            {accessToken ? (
                <ShareID
                    debug={true}
                    token={accessToken}
                    language="fr"
                    onFail={(data: any) => {
                        console.log("verification failed : ", data);
                    }}
                    onSuccess={(data: any) => {
                        handleVerify();
                    }}
                    onExit={(data: any) => console.log("exit", data)}
                    theme={{
                        colors: {
                            primary: {
                                active: "#1956A8",
                                hover: "#0F3465",
                                disabled: "#9FC1EF",
                            },
                        },
                    }}
                />
            ) : (
                <Loader />
            )}
            <div className="">
                <p className="mb-2 text-center">Vous n'avez pas le document d'identité sur vous ?</p>
                <Button
                    type={userType === "partner" ? Button_Type.SECONDARY : Button_Type.PRIMARY}
                    style={Button_Style.OUTLINED}
                    label="Utiliser un scan du document"
                    onClick={() => setIsShareID(false)}
                    className="m-auto"
                />
            </div>
        </div>
    );
};

export default ShareIDVerification;
