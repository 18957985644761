import "./style.scss";

import * as Yup from "yup";

import { FC, useContext, useState } from "react";
import { Field, Form, Formik } from "formik";

import Alert from "../Alert";
import Button from "../Button";
import Input from "../Input";
import Loader from "../Loader";
import Select from "../Select";
import SelectCountry from "../SelectCountry";
import { UserContext } from "../../contexts/UserContext";
import VerifyDatabaseDocumentResult from "./VerifyDatabaseDocumentResult";
import { useGetDarkWebDocument } from "../../api/analysis";
import { useSearchLostDocument } from "../../api/lost";

interface FormValues {
    documentNumber: string;
    documentType: string;
    documentCountry: string;
}

const options = [
    { value: "passport", label: "Passeport" },
    { value: "id", label: "Carte d'identité" },
    { value: "residency_permit", label: "Carte de séjour" },
    { value: "driver_permit", label: "Permis de conduire" },
];

const VerifyDatabaseDocument: FC<{ type: string; setType: (type: string) => void }> = ({ type, setType }) => {
    const [result, setResult] = useState<any>();
    const { mutate: searchDocument, isLoading } = useSearchLostDocument();
    const { userType } = useContext(UserContext);
    const { mutate: getDarkWebDocument } = useGetDarkWebDocument();

    const handleCheckDarkWeb = (number: string) => {
        const data: any = {
            type: "partner",
            number,
        };

        getDarkWebDocument(data, {
            onSuccess: (response) => {
                console.log(response);
                setResult((prev: any) => ({ ...prev, darkWeb: response }));
            },
            onError: (error) => {
                console.error("Erreur lors de la recherche:", error);
                setResult((prev: any) => ({ ...prev, darkWeb: false }));
            },
        });
    };
    const handleSubmit = async (values: FormValues) => {
        const searchParams: any = {
            type: userType,
            docType: values.documentType,
            idNumber: values.documentNumber,
        };
        handleCheckDarkWeb(values.documentNumber);
        searchDocument(searchParams, {
            onSuccess: (response) => {
                setResult((prev: any) => ({
                    ...prev,
                    status: response.status,
                    numeroDocument: values.documentNumber,
                    country: values.documentCountry,
                    typeDocument: values.documentType,
                }));

                if (response.status === "fraude" || response.status === "error") {
                    setType("warning");
                }
            },
            onError: (error) => {
                console.error("Erreur lors de la recherche:", error);
            },
        });
    };

    const refresh = () => {
        setResult(undefined);
    };

    const documentNumberSchema = (documentType: string) => {
        switch (documentType) {
            case "passport":
                return Yup.string()
                    .length(9, "Le numéro de passeport doit être de 9 caractères") // Exemple pour passeport français
                    .matches(/^\d{2}[A-Z]{2}\d{5}$/, "Le numéro de passeport doit être au format XXAAXXXXX")
                    .required("Ce champ est requis");
            case "id":
                return Yup.string()
                    .matches(
                        /^\d{12}$|^[a-zA-Z0-9]{12}$|^[a-zA-Z0-9]{9}$/,
                        "Le format doit être de 12 chiffres, 12 caractères alphanumériques ou 9 caractères alphanumériques",
                    )
                    .required("Ce champ est requis");
            case "driver_permit":
                return Yup.string()
                    .length(12, "Le numéro de permis doit être de 12 caractères")
                    .matches(/^[a-zA-Z0-9]+$/, "Veuillez entrer uniquement des caractères alphanumériques")
                    .required("Ce champ est requis");
            case "residency_permit":
                return Yup.string()
                    .min(9)
                    .max(10)
                    .matches(/^[0-9]+$/, "Veuillez entrer uniquement des chiffres")
                    .required("Ce champ est requis");
            default:
                return Yup.string().min(9).required("Ce champ est requis");
        }
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div className="VerifyDatabaseDocument h-100 d-flex flex-column">
            <h1 className="VerifyDatabaseDocument__title h3">
                {!result ? "Vérifier un document" : result?.status === "not_found" ? "Document inconnu" : "Document reconnu"}
            </h1>

            {!result ? (
                <Formik
                    initialValues={{ documentNumber: "", documentType: "", documentCountry: "France" }}
                    validationSchema={Yup.object().shape({
                        documentNumber: documentNumberSchema(type),
                        documentType: Yup.string().required("Ce champ est requis"),
                        documentCountry: Yup.string().required("Ce champ est requis"),
                    })}
                    onSubmit={handleSubmit}>
                    {({ values, handleChange, handleSubmit, errors, isValid }: any) => (
                        <Form onSubmit={handleSubmit}>
                            <div className="d-flex flex-column align-items-end justify-content-between h-100 ">
                                <div className="w-100 ">
                                    <div className="d-flex gap-1 mb-1">
                                        <Field
                                            component={SelectCountry}
                                            name="documentCountry"
                                            value={values.documentCountry}
                                            onChange={handleChange}
                                            id="documentCountry"
                                        />
                                        <Field
                                            name="documentType"
                                            onChange={handleChange}
                                            value={values.documentType}>
                                            {({ field }: any) => (
                                                <Select
                                                    className="w-50"
                                                    field={field}
                                                    placeholder="Type de document"
                                                    options={options}
                                                    handleChange={(value) => setType(value)}
                                                    errorMessage={!isValid ? errors.documentType : ""}
                                                />
                                            )}
                                        </Field>
                                    </div>

                                    <Input
                                        type="text"
                                        name="documentNumber"
                                        placeholder="n° de document"
                                        required
                                        onChange={handleChange}
                                        value={values.documentNumber}
                                        className="mb-1"
                                        errorMessage={!isValid ? errors.documentNumber : ""}
                                    />
                                    {values.documentNumber && <Alert type="info">ID Protect ne conserve aucune données utilisateur</Alert>}
                                </div>
                                <Button
                                    className="btn btn-primary"
                                    label="Vérifier"
                                    disabled={!isValid || !values.documentNumber || !values.documentType}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            ) : (
                <VerifyDatabaseDocumentResult
                    result={result}
                    refresh={refresh}
                />
            )}
        </div>
    );
};

export default VerifyDatabaseDocument;
